import React, { useContext, useState, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import classNames from "classnames"
import axios from "axios"
import firebase from "firebase"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import PrivateRoute from "layout/PrivateRoute"
import ActionButtons from "elements/ActionButtons"
import { generateFormField } from "elements/Form/services/form"
import guidelines from "../../../Epharmacy/utils/uploadGuidelines.json"

import { useSession } from "services/hooks/useSession"
import { getSignedInUser } from "../../../Auth/services/user"
import { getUserData, getUserAddresses } from "../../../Auth/services/signin"
import { checkIfUserAlreadyExists } from "../../../Auth/services/signup"
import { AuthContext } from "../../../../context/AuthContext"
import { formSchema } from "../../../MedEnsure/Request/utils/formSchema"
import { parseTermsAndConditions } from "../../services/parseTermsAndConditions"
import { patientSmsContent } from "../../utils/templates/patientEnrollmentSmsContent"
import { PatientEnrollmentContext } from "../../EnrollmentContext/PatientEnrollmentContext"
import { patientEnrollmentTicketBody } from "../../utils/templates/patientEnrollmentZendeskTemplate"
import {
  createUserDocument,
  updateUserDocument,
  createAddressDocument,
  updateAddressDocument,
  createUserAuth,
} from "../Form/services/user"
import {
  zendeskApi,
  generateRequestTemplate,
  generateUploadFiles,
  zendeskUploadFiles,
} from "services/zendeskService"

import styles from "../../utils/enrollment.module.scss"

import {
  GATSBY_SUBMIT_PATIENT_ENROLLMENT_WEBHOOK,
  GATSBY_ZENDESK_EMAIL,
  GATSBY_ZENDESK_API_KEY,
  GATSBY_ZENDESK_SUBDOMAIN,
  GATSBY_TELERIVET_URL,
  GATSBY_TELERIVET_API_KEY,
  GATSBY_TELERIVET_ROUTE_ID,
  GATSBY_TELERIVET_PROJECT_ID,
  GATSBY_ENV,
} from "gatsby-env-variables"
import { AppContext } from "../../../../context/AppContext"
import { values } from "lodash"

let { parseFormField } = require("services/airtable")

const PatientEnrollmentForm = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { module, pageContent } = pageContext
  const { patientEnrollmentState, patientEnrollmentDispatch } = useContext(
    PatientEnrollmentContext
  )
  const { state, dispatch } = useContext(AppContext)
  const { authState } = useContext(AuthContext)
  const { patientEnrollment } = state
  const {
    formValues,
    isFormValuesEmpty,
    initializeFormValues,
    handleFormChange,
    clearFormValues,
  } = useSession("patientEnrollment")

  let termsAndConditions = pageContent[0]?.body?.childMarkdownRemark?.html
  let parsedTerms = parseTermsAndConditions(termsAndConditions)
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  let userData = getSignedInUser()?.userData
  let userAddresses = getSignedInUser()?.addresses

  let mgcareZendeskConfig = {
    email: GATSBY_ZENDESK_EMAIL,
    apiKey: GATSBY_ZENDESK_API_KEY,
    apiUrl: GATSBY_ZENDESK_SUBDOMAIN,
  }

  useEffect(() => {
    initializeFormValues()
    // eslint-disable-next-line
  }, [])

  const handleSuccessCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleErrorCallback = () => {
    navigate(pageContext.nextPath)
    setLoading(false)
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    let tempPatientState = {
      ...patientEnrollmentState,
      ...values,
      terms: parsedTerms,
    }

    console.log("temp patient state: ", tempPatientState)

    // Comment this out if you want to continue testing out form submissions
    clearFormValues()

    // Creates token for ID upload
    const uploadedFiles = await zendeskUploadFiles(
      patientEnrollmentState?.documents,
      null,
      mgcareZendeskConfig
    )

    const generatedFileToken = await generateUploadFiles(uploadedFiles)

    try {
      console.log("in the try")
      const { firstName, lastName, email, mobileNumber } = tempPatientState

      console.log("user data: ", getSignedInUser())

      // Adds a test tag if not in production
      let zendeskTags = ["patient_enrollment", "pulsecare", GATSBY_ENV]
      if (GATSBY_ENV !== "production") zendeskTags = [...zendeskTags, "test"]

      // Create a new Zendesk ticket
      const requestTemplate = await generateRequestTemplate({
        subject: `PCP Patient Enrollment Form of ${firstName} ${lastName}`,
        email: email,
        template: patientEnrollmentTicketBody,
        templateObjects: tempPatientState,
        tags: zendeskTags,
        uploadTokens: generatedFileToken,
      })

      const zendeskResponse = await zendeskApi({ ...mgcareZendeskConfig }).post(
        "/requests.json",
        requestTemplate
      )

      const generatedZendeskId = zendeskResponse?.data?.request?.id

      // Send an SMS via Telerivet
      const telerivetMessageBody = {
        content: patientSmsContent(generatedZendeskId),
        to_number: `+63${mobileNumber}`,
        route_id: GATSBY_TELERIVET_ROUTE_ID,
      }

      await axios.post(
        GATSBY_TELERIVET_URL,
        { ...telerivetMessageBody },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: GATSBY_TELERIVET_API_KEY,
            route_id: GATSBY_TELERIVET_ROUTE_ID,
            project_id: GATSBY_TELERIVET_PROJECT_ID,
          },
        }
      )

      if (userData?.id && userAddresses?.id) {
        // update user document
        let addressesUid = userAddresses.id
        await updateAddressDocument({
          values: tempPatientState,
          addressesUid: userData?.addresses,
          oldAddresses: userAddresses?.addresses,
        })

        await updateUserDocument({
          values: tempPatientState,
          user: userData,
          addressesUid,
        })
      } 
      console.log("user data: ", userData)
      console.log("email: ", tempPatientState?.email)
      if (!userData) {
        console.log("here in checking")
        userData = await checkIfUserAlreadyExists(tempPatientState?.email)
        console.log("no user data")
      }
      let authUid = userData?.authUid

      console.log("authid: ", authUid )

      // Create an account if user has no account yet
      let tempPassword = `MedGrocer2024ACE!`
      
      if (!authUid) {
        console.log("creating an account")
        let createdUser = await firebase
          .auth()
          .createUserWithEmailAndPassword(
            tempPatientState.email,
            tempPassword
          )
        authUid = createdUser.user.uid
        console.log("created user in auth")
        //code below sends email verification after user has been created
        //createdUser.user.sendEmailVerification({ url: GATSBY_LOGIN_REDIRECT })

        //signin and create a user document
        let response = await firebase
        .auth()
        .signInWithEmailAndPassword(tempPatientState.email, tempPassword)
        console.log("response: ", response)

        const authUser = response?.user;
        console.log("authUser: ", authUser);

        console.log("creating a user document 2")
          console.log("values: ", values)
          let addressesUid = ""
          let addressResponse = await createAddressDocument({
            values,
          })
          addressesUid = addressResponse.id

          console.log("address response: ", addressResponse)
          console.log("address uid: ", addressesUid)
  
          await createUserDocument({
            user: authUser,
            values,
            addressesUid,
          })
        }
      
      handleSuccessCallback()
    } catch (error) {
      console.log("here")
      console.log("error: ", error)
      await axios.post(GATSBY_SUBMIT_PATIENT_ENROLLMENT_WEBHOOK, {
        ...tempPatientState,
        files: generatedFileToken,
      })
      handleErrorCallback()
    }
  }

  let hasBeenVerified = state.auth.hasBeenVerified
  console.log("has been verified ", hasBeenVerified)

  return (
    <PrivateRoute isPrivate={true}>
      <Layout {...module} pageContext={pageContext}>
        <Container isCentered>
          <Formik
            initialValues={
              isFormValuesEmpty ? 
                { 
                  ...patientEnrollmentState, 
                  ...state?.auth,
                  emailAddress:
                    state?.auth?.email ||
                    getSignedInUser()?.email ||
                    patientEnrollment?.emailAddress,
                  ...authState 
                }
                : { ...formValues }
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, setFieldValue, isValid, submitCount }) => (
              <Form onChange={(event) => handleFormChange(values, event)}>
                {sectionFormFields.map((section) => (
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    className="mt-1 mb-3"
                    isSectionRequired={section?.isSectionRequired}
                    helper={section?.helper}
                  >
                    {section?.message && (
                      <Message color={section?.messageColor || "light"}>
                        {section?.message === "validIDTypes" ? (
                          <div>
                            Valid IDs include:
                            <ul>
                              <li>SC/PWD ID</li>
                              <li>UMID</li>
                              <li>GSIS ID</li>
                              <li>SSS ID</li>
                              <li>Driver’s license</li>
                              <li>Passport</li>
                              <li>
                                Other government-issued IDs with full name and
                                birthday
                              </li>
                            </ul>
                          </div>
                        ) : (
                          section?.message
                        )}
                      </Message>
                    )}{" "}
                    {section?.section === "Consent and Authorization" && (
                      <Fragment>
                        <div
                          className={classNames(
                            "message-body my-2 is-size-4",
                            styles["collapsible"]
                          )}
                        >
                          <div className="content">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: termsAndConditions,
                              }}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        const isEmailField = field?.name === 'email'
                        //console.log("isemailfield: ", isEmailField)
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                              guidelines,
                              state: patientEnrollmentState,
                              dispatch: patientEnrollmentDispatch,
                              type: "patient-enrollment",
                              hasBeenVerified: hasBeenVerified
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }

                      return null
                    })}
                  </Section>
                ))}
                {!isValid && submitCount > 0 && (
                  <Message color="danger">
                    You may have missed some required fields. Please scan
                    through the form and check if your information is complete.
                  </Message>
                )}

                <ActionButtons
                  back={{ label: "Back", link: pageContext.backPath }}
                  submit={{
                    label: "Submit",
                    loading: loading,
                    disabled:
                      !values?.patientConsentAndAuthorization?.length ||
                      patientEnrollmentState?.documents?.length === 0,
                  }}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Layout>
    </PrivateRoute>
  )
}

export default PatientEnrollmentForm
